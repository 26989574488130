.epigraphic {
  width: 100%;
}

.top-nav {
  border-bottom: 2px solid $gold;
  display: flex;
  align-items: center;
  color: $gold;
  font-size: 16px;
  line-height: 20px;
  margin: -2px 0 30px;
  padding-bottom: 6px;
  &__title,
  &__link {
    display: flex;
    align-items: center;
    flex: 1 1;
  }
  &__title {
    justify-content: center;
    svg {
      margin-right: .5em;
      margin-top: -5px;
    }
  }
  &__link {
    &:last-child {
      justify-content: flex-end;
    }
    a {
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      height: 12px;
      fill: $gold;
    }
    span {
      padding: 0 5px;
      & + span {
        display: none;
      }
    }
    &:hover {
      span {
        display: none;
        & + span {
          display: block;
        }
      }
    }
  }
}

.name-card {
  line-height: 1.4;
  & + & {
    margin-top: .6em;
  }
  &__title {
    display: block;
    .name-card--lead & {
      color: #333;
      font-size: 24px;
    }
    .link {
      color: #616161;
      text-decoration: underline !important;
    }
  }
  &__subtitle {
    display: block;
    @extend .muted;
    line-height: 20px;
    &._md {
      font-size: 18px;
      font-weight: normal;
    }
    &._xl {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &--icon {
    position: relative;
    padding-left: 30px;
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      color: $gold;
    }
  }
  &--lead {
    .icon {
      top: .4em;
    }
  }
}


.card {
  &__section {
    margin-bottom: 36px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    position: relative;
    margin-bottom: 1em;
    color: $gold;
    font-size: 16px;
    line-height: 19px;
    &:after {
      border-top: 1px solid #d8d8d8;
      position: absolute;
      top: 50%;
      left: 165px;
      right: 0;
      display: block;
      margin-top: -2px;
      content: '';
    }
  }
  &__row {
    display: flex;
    & + & {
      margin-top: 1.3em;
    }
    &--translate {
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: .5em;
      padding-bottom: 10px;
    }
  }
  &__col {
    display: flex;
    .quick-view & {
      padding: .2em 0;
      font-size: 16px;
    }
    &--label {
      flex: 1 1 180px;
      max-width: 168px;
      font-size: 16px;
    }
    &--text {
      flex: 1 1;
    }
  }
}
