// audio-player styles

.btn--play-audio,
.btn--pause-active {
  margin: auto;
  padding: 0;
  outline: none !important;
  position: relative;
}

.audio-player {
  z-index: 1;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gold;
  user-select: none;
  &--md {
    width: auto;
    height: 100%;
    margin-right: 16px;
    padding: 8px 4px 4px 16px;
    background: #fff;
    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 32px;
      background: linear-gradient(to right, hsla(0,0%,100%,1) 0%,hsla(0,0%,100%,0) 100%);
      content: '';
    }
  }
  
  & + & {
    margin-top: 20px;
  }
  &--not-logged {
    border: none;
    display: none;
  }
  
  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: $gold;

    &--play {
      width: 14px;
      height: 19px;
      left: 50%;
      fill: #fff;
    }
  }
  
  &__play-btn {
    flex: 0 1 50px;
    
    &__play-btn--disable {
      display: none;
    }

    .btn--play-audio {
      position: absolute;
      top: 0;
      bottom: 0;
      max-width: 50px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background-color: $gold;
      font-size: 0;
      border-radius: 0;
    }
    
    @at-root .btn--pause-audio{
      padding: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      max-width: 50px;
      width: 100%;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background-color: $gold;
      font-size: 0;
      border-radius: 0;
      display: none;
      
      &:active,
      &:hover {
        color: $color-hover-header;
      }
      
      &::before,
      &::after {
        content: '';
        width: 5px;
        height: 19px;
        background-color: #fff;
        border-radius: 2px;
      }
      
      &::before {
        margin-right: 4px;
      }
    }
  }

  &__name-track--disable {
    display: none;
  }
  
  &__inner {
    flex: 0 1;
    flex-basis: calc(100% - 30px);
    padding: 11px 11px 5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
    
    @include media('>tablet') {
      flex-basis: calc(100% - 50px);
    }
    &--not-logged {
      flex: none;
      min-width: 150px;
      padding: 0;
      z-index: 10;
      background-color: #fff;
    }
  }
  
  &__timeline {
    flex: 1 1 100%;
    margin-bottom: 5px;
  }
  
  @at-root .progress {
    display: flex;
    height: 10px;
    overflow: hidden;
    font-size: .75rem;
    background-color: #F0F0F0;
    cursor: pointer;
    
    &-bar {
      width: 0%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: $gold;
      //transition: width .3s ease;
    }
  }
  
  &__inner-info {
    flex: 1 1 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
  }
  
  &__info {
    flex: 0 1 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    color: $gold;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
  }
  
  &__timer {
    margin-right: 10px;
  }
  
  &__volume {
    flex: 0 1 70px;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  
  &__toggle-volume {
    flex: 0 1 auto;
    position: relative;
    
    &--none {
      
      &::before {
        content: '';
        position: absolute;
        width: 120%;
        height: 1px;
        top: 50%;
        left: -10%;
        transform: translateY(-50%) rotate(-45deg);
        background-color: $dark-gray;
      }
    }
    
    @at-root .btn--toggle-volume {
      padding: 0;
      margin: 0;
      font-size: 0;
      border: 0;
      
      .icon-speaker {
        width: 14px;
        height: 12px;
        display: block;
        fill: transparent;
      }
    }
  }
  
  &__volume-control {
    position: relative;
    cursor: pointer;
    flex: 0 1 50px;
    z-index: 2;
    
    @at-root .progress--volume-control {
      height: 5px;
    }
  }
}

.progress {
  &-bar--volume {
    position: relative;
    z-index: 0;
  }
}

.jump__link {
  color: $gold;
  cursor: pointer;
}
