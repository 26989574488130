// search styles

.search {
  display: none;
  position: absolute;
  z-index: 600;
  height: 96px;
  max-width: 700px;
  width: 100%;
  top: 190px;
  left: 50%;
  padding-right: 95px;
  transform: translateX(-50%);
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;


  .active-search & {
    display: block;
  }

  @include media('>widescreen') {
    width: 100%;
    max-width: 950px;
  }

  input {
    display: block;
    position: absolute;
    padding: 0 100px 0 30px;
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 95px;
    color: $dark-gray;
    border-radius: 5px;

    &:focus {
      box-shadow: inset 0 0 5px 0 rgba(204,204,204, .3);
      outline: none;
      opacity: 1;
    }
  }

  @at-root .btn--search-popup {
    position: absolute;
    width: 95px;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 0;
    text-align: center;

    &:hover svg {
      fill: $color-hover-header;
      text-decoration: none;
    }

    &:active svg {
      fill: $color-active-header;
      text-decoration: none;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      fill: #828282;
    }
  }


}

