// pagination styles
.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    display: inline-block;
    font-weight: bold;
    line-height: 20px;
    min-width: 30px;
    text-align: center;
    font-size: 12px;
    color: $gold;

    &:hover {
      svg {
        stroke: currentColor;
        fill: transparent;
      }

    }
  }

  .active {
    &:hover a,
    a {
      color: $dark-gray;
      text-decoration: underline;
    }
  }

  .disabled {
    &:hover svg,
    svg {
      stroke: $very-light-gray;
    }
  }

  &__svg {
    fill: transparent;
    stroke: $gold;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 18px;
  }

  &__next, &__prev {
    cursor: pointer;
    &:hover {
      svg {
        fill: transparent;
      }
    }
  }

  &__prev {
    .pagination__svg {
      stroke: $very-light-gray;
    }
    &--active {
      .pagination__svg {
        stroke: $gold;
      }
    }
  }

}
