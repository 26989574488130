// video-slider styles
.slider {
  position: relative;

  &__inner {
    //max-width: 845px;
    padding: 20px 0;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }

  .owl-next {
    position: absolute;
    right: 0;
  }

  &__item {
    position: relative;
    display: block;
  }

  &__play-nav {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .slider__item--video & {
      background-color: rgba(130,130,130, .5);
    }

    .slider__item:hover & {
      display: block;
    }

    @at-root .btn--play-video {
      position: absolute;
      display: none;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 60px;
      height: 60px;
      border: 1.5px solid #fff;
      border-radius: 50%;

      .slider__item--video:hover &{
        border: 1.5px solid $gold;
      }

      .slider__item--video & {
        display: flex;
      }

      .icon-play-video {
        margin-left: 3px;
        display: block;
        fill: #fff;
        width: 20px;
        height: 25px;

        .slider__item--video:hover & {
          fill: $gold;
        }
      }
    }
  }

  &__serial-number {
    position: absolute;
    padding: 3px 5px 1px;
    bottom: 0;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background: rgba(255,255,255, .5);
    border-radius: 0 3px 0 0;


    .slider__item--video & {
      display: none;
    }
  }
}

.btn--prev-slider-video,
.btn--next-slider-video {
  padding: 0;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 0;
  width: 28px;
  height: 28px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #ccc;
  z-index: 10;
  
  &:hover {
    border: 2px solid #fff;

    .icon-arrow-left,
    .icon-arrow-right {
      stroke: #fff;
    }
  }

  .icon-arrow-left,
  .icon-arrow-right {
    flex: 0 1 12px;
    width: 12px;
    height: 16px;
    stroke: #CCCCCC;
    fill: transparent;

    .btn:hover & {
      stroke: #fff;
    }
  }
}

.btn--next-slider-video {
  right: 10px;
  left: auto;
}

.btn--none {
  display: none;
}

.icon-arrow-left,
.icon-arrow-right {
  width: 12px;
  height: 16px;
  stroke: #CCCCCC;
  fill: transparent;
}


