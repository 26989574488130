// person-card styles
.person-card {

  &__tabs {
    @extend .nav-tabss;
    width: 65px;

    &::before {
      background-color: transparent;
    }
  }

  &__tab-item {
    @extend .nav-tabss__item;

    &--active {
      @extend .nav-tabss__item--active;
    }
  }

  &__tab-link {
    @extend .nav-tabss__link;
    background-color: #fff;
    border: 1px solid $gold;

    .nav-tabss__item--active & {
      background-color: $gold;
    }

    &::after {
      background-color: #fff;

      .nav-tabss__item--active & {
        background-color: $gold;
      }
    }

    &::before {
      background-color: $gold;

      .nav-tabss__item--active & {
        background-color: $gold;
      }
    }

    .icon-user,
    .icon-list {
      fill: $gold;

      .nav-tabss__item--active & {
        fill: #fff!important;
      }
    }

    .icon-user {
      display: block;
      width: 15px;
      height: 15px;
    }

    .icon-list {
      display: block;
      width: 11px;
      height: 15px;
    }
  }

  &__tab {
    display: none;
    flex-flow: row wrap;
    position: relative;
    background-color: #fff;
    margin-top: -2px;
    border: 1px solid $gold;
    z-index: 10;
    padding: 18px;

    @include media('>desktop') {
      flex-flow: row nowrap;
    }

    &--active {
      display: flex;
    }
  }

  &__inner-img {
    display: block;
    flex: 0 0 100%;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    @include media('>desktop') {
      flex: 0 0 115px;
      width: 115px;
      margin-right: 20px;
      margin-bottom: 0;
    }

  }

  &__info-list {
    list-style-type: none;
    padding: 6px 0 0 0;
    margin: 0;

    &-item {
      line-height: 22px;
      font-size: 16px;
      color: $gray-brown;

      & + & {
        margin-top: 13px;
      }

      &:first-of-type {
        font-weight: 500;
        color: $gold;
      }
    }
  }
}