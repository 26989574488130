.quick-view-land {
  position: relative;
  max-width: 890px + 90px;
  margin: 15px auto;
  padding: 0 45px;
  &__tab {
    display: flex;
    justify-content: flex-end;
    &-item {
      overflow: hidden;
      position: relative;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      height: 35px;
      margin-left: -30px;
      padding: 3px 40px 3px 25px;
      &:before {
        border-radius: 3px 0 0 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -14px;
        width: 100%;
        background: #f2f2f2;
        content: '';
        transform: skew(-22deg);
        transform-origin: top right;
      }
      &:last-child {
        padding: 3px 13px 3px 20px;
        &:before {
          background: #fff;
        }
      }
      .make-starred {
        height: 20px;
      }
      svg {
        width: 22px;
        height: 20px;
      }
    }
    &-inner {
      z-index: 2;
      position: relative;
      line-height: 1;
      font-size: 13px;
      overflow: hidden;
    }
  }
  &__content {
    background: #fff;
    padding: 40px;
    .btn--catalog {
      width: 100%;
      margin-top: 30px;
      max-width: initial;
    }
  }
  .mfp-close {
    overflow: hidden;
    top: 2px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 46px;
    opacity: 1;
  }
}
