@import 'plugins/old';
/*! END OLD CSS */

/*! New CSS */
@import '../../node_modules/include-media/dist/include-media';
@import 'plugins/owl.carousel';
@import 'plugins/owl.theme.default';
@import 'plugins/owl.theme.green';
@import 'plugins/magnific-popup';
@import 'plugins/auto-complete';
@import 'plugins/PAcalendar.min';
@import 'settings/template';
@import 'settings/variables';

// Import Modules
@import '../_modules/navbar/navbar';
@import '../_modules/search/search';
@import '../_modules/footer/footer';
@import '../_modules/wide-img/wide-img';
@import '../_modules/filter-search/filter-search';
@import '../_modules/breadcrumbs/breadcrumbs';
@import '../_modules/person-card/person-card';
@import '../_modules/slider/slider';
@import '../_partials/audio-player/audio-player';
@import '../_modules/cemetery/cemetery';
@import '../_modules/epigraphic/epigraphic';
@import '../_partials/gallery2/gallery2';
@import '../_modules/interview/interview';
@import '../_modules/quick-view/quick-view';
@import '../_modules/interview/interview';

//Import Partials
@import '../_partials/form/input/input';
@import '../_partials/form/checkbox/checkbox';
@import '../_partials/table/table';
@import '../_partials/content-tabs/content-tabs';
@import '../_partials/translate-section/translate-section';
@import '../_partials/filters/filters';
@import '../_partials/keywords/keywords';
@import '../_partials/icon/icon';
@import '../_partials/tooltip/tooltip';
@import '../_partials/pagination/pagination';

//style pages

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
