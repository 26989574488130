.table {
  &--card {
    margin-bottom: 20px;
  }
  &--va-top {
    .table {
      &__td {
        vertical-align: top;
      }
    }
  }
  &--search-interview {
    margin-bottom: 0;
    border-bottom: 1px solid $table-border-bottom-color;
  }
  &--cemetery {
    margin-bottom: 0;
  }
  &--separated {
    table-layout: fixed;
    border-collapse: separate;
    margin-bottom: 0;
  }

  &__tbody {
    &--group {
      tr {
        &:first-child {
          td {
            padding-top: 15px;
            //background: #f00;
            border-top-color: $table-border-top-color !important;
          }
        }
        &:last-child {
          td {
            padding-bottom: 15px;
            //background: #00f;
            border-bottom-color: $table-border-bottom-color !important;
          }
        }
      }
      td {
        border-color: transparent !important;
        padding-top: 6px;
        padding-bottom: 6px;
        &[rowspan] {
          vertical-align: top;
          border-bottom-color: $table-border-bottom-color !important;
        }
      }
      &:hover {
        td {
          background: $table-hover-bg !important;
        }
      }
    }
  }

  &__th {
    padding: 15px 16px;
    background: $table-hover-bg;
    line-height: 20px;
    font-size: 16px;
    color: #B3882A;
    font-weight: normal;
    text-transform: none;
    border: 2px solid $table-border-top-color;
    height: 52px;
    white-space: nowrap;

    &-inner {
      &--sort {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .table__th-text {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 8px;
        }
        &-active {
          .icon-angle-down {
            transform: rotate(-180deg)
          }
        }
      }
    }
    .table--separated & {
      border-width: 0 0 1px 2px!important;
      &:first-of-type {
        border-left-width: 0!important;
        width: 68px;
      }
    }
    &--lang {
      width: 70px;
    }
    &--square {
      width: 110px;
    }
    &--year-born {
      width: 110px;
    }
    &--info {
      width: 68px;
    }
    &--more,
    &--catalog {
      width: 44px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    &--catalog {
      width: 30px;
    }
    &--monuments {
      width: 190px;
    }
    &--favs {
      width: 52px;
      text-align: center;
    }
    &--photo {
      width: 78px;
    }
    &--fio {
      width: 380px;
    }
    &--place {
      width: 220px;
    }
  }

  .icon-melody {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 20px;
  }

  .icon-play, .icon-pause {
    display: block;
    width: 24px;
    height: 25px;
    fill: transparent;
  }

  .icon-pause {
    fill: #B3882A;
  }

  .icon-angle-down {
    display: block;
    width: 15px;
    height: 8px;
    fill: $gold;
    //transition: all 250ms;
  }

  .icon-star {
    display: inline-block;
    width: 22px;
    height: 20px;
    fill: $gold;
  }

  .icon-eye {
    display: inline-block;
    width: 22px;
    height: 15px;
    fill: currentColor;
  }

  .icon-camera {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px;
    fill: $gold;
  }
  .icon-info {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    fill: transparent;
  }
  .icon-book {
    display: block;
    margin: 0 auto;
    width: 18px;
    height: 24px;
  }
  .icon-add-image {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px;
  }

  &__td {
    position: relative;
    padding: 15px 16px;
    font-size: 14px;
    color: #616161;
    vertical-align: middle;
    border: 0;
    line-height: 1.3;
    &--audioplayer {
      padding: 0;
    }
    &--lang {
      width: 70px;
      text-align: center;
    }
    &--catalog {
      width: 30px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    &--more {
      width: 44px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
    &--tooltip {
      position: relative;
    }
    &--starter {
      width: 68px;
      height: 50px;
      padding-top: 0;
      padding-bottom: 0;
      background: $table-hover-bg;
      text-align: center;
      vertical-align: middle !important;
      & + & {
        background: linear-gradient(to right, #fff 2px,#f2f2f2 2px,#f2f2f2 100%);
      }
    }
    &--text-align-center {
      text-align: center;
    }
    &--popover {
      position: relative;
    }
    .table--separated & {
      border-top: 1px solid $table-border-top-color;
      border-bottom: 1px solid $table-border-bottom-color;
    }

    .table--card & {
      padding: 5px 35px 13px 0;
      &:last-child {
        max-width: 150px;
      }
    }

    .icon-book {
      cursor: pointer;
    }
  }

  &__td-text {
    display: block;
    padding: 15px 16px;
  }

  &__tr {
    &:hover {
      .table__td {
        background: $table-hover-bg;
      }
    }

    .table--card & {
      &:first-child {
        .table__td {
          padding-top: 0;
        }
      }
      &.table__tr--head:not(:last-child) {
        .table__td {
          border-bottom: 1px solid $table-border-bottom-color;
        }
      }
      &:nth-child(2) {
        .table__td {
          padding-top: 10px;
        }
      }
    }

    &--body {
      border: 0;

      &:hover {
        background-color: transparent;
      }
    }

    &--no-hover:hover {
      .table__td {
        background: transparent;
      }
    }
  }

}

// service classes:
._vm {
  vertical-align: middle!important;
}
._vt {
  vertical-align: top!important;
}
._tc {
  text-align: center!important;
}
._nopadding {
  padding: 0 !important;
}
.muted {
  color: #808080!important;
}

.make-starred {
  user-select: none;
  &__control {
    display: block;
    margin: 0;
    position: relative;
    cursor: pointer;
  }
  &__v {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 1px;
    height: 1px;
    clip: rect(0,0,0,0);
    & ~ svg {
      fill: currentColor !important;
      color: $table-border-bottom-color;
    }
    &:checked ~ svg {
      color: $gold;
    }

    &--border-bottom1 {
      border-bottom: 1px solid $table-border-bottom-color;
    }
  }
}
