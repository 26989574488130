.gallery2 {
  position: relative;
  max-width: 362px;
  &--main {
    .owl-nav {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 15px;
      right: 15px;
      height: 0;
      transform: translateY(-50%);
      > button {
        border: 2px solid !important;
        border-radius: 50%;
        display: block;
        width: 28px;
        height: 28px;
        color: #fff !important;
        outline: none !important;
        span {
          display: block;
          line-height: 24px;
          font-size: 30px;
        }
      }
    }
    .owl-prev {
      float: left;
    }
    .owl-next {
      float: right;
    }
  }
  &--thumbs {
    margin-top: 16px;
    .owl-item {
      position: relative;
      &.current {
        &:before {
          box-shadow: inset 0 0 0 3px #f2f2f2;
          background: rgba(#fff, .25);
        }
      }
    }
  }
  &__popup-link {
    display: block;
    position: absolute!important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 1000;
    }
  }

  &__inner {
    position: relative;
  }
}

.gallery2__img {
  cursor: pointer;
}