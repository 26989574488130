.translate-section {
  overflow: hidden;
  overflow-y: auto;
  max-height: 160px;
  &--full {
    overflow: initial;
    max-height: none;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background: #cecece;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
    counter-reset: list;
  }
  &__item {
    flex: 1 1 100%;
    display: flex;
    align-items: baseline;
    min-width: 100%;
    counter-increment: list;
    font-size: 14px;
    &:before {
      order: 1;
      flex: 1 1 50px;
      align-self: flex-start;
      max-width: 50px;
      content: counter(list);
      color: #adadad;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      opacity: 0;
    }
    &:first-child,
    &:last-child,
    &:nth-child(5n) {
      &:before {
        opacity: 1;
      }
    }
    &--heading {
      color: #808080;
      &:before {
        opacity: 0 !important;
      }
    }
  }
  &__original {
    flex: 1 1 130px;
    max-width: 115px;
    text-align: right;
    color: #333333;
    font-size: 20px;
    line-height: 24px;
    .translate-section__item--heading & {
      color: #808080;
      font-size: 14px;
    }
  }
  &__translated {
    flex: 1 1;
    order: 2;
    line-height: 24px;
  }
}
