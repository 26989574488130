@import '../settings/variables';

.page {
  padding-bottom: 40px;

  @include media('>desktop') {
    padding-bottom: 100px;
  }
  &__keywords {
    margin-bottom: 2px;
  }
  
  &__pagination-result-table {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -60px;
    padding: 20px 0;
  }
  
  &__inner-content {
    display: flex;
    & > .row {
      flex: 1;
    }
  }
  
  &__sidebar {
    flex: 1 1 100%;
    margin-bottom: 20px;
    
    @include media('>desktop') {
      flex: 0 1 percentage($width-sidebar/$width-inner-content);
      max-width: percentage($width-sidebar/$width-inner-content);
    }
  }
  
  &__content {
    flex: 1 1 100%;
    
    &-text {
      .b-int__block {
        box-shadow: none;
        border: 0;
        padding: 0;
      }
    }
    
    &--and-sidebar {
      @include media('>desktop') {
        margin-left: 30px;
        flex: 0 1 percentage($width-content/$width-inner-content);
        max-width: percentage($width-content/$width-inner-content);
      }
    }
  }
  
  &__content-pagination-audio {
    display: flex;
    justify-content: flex-end;
    &--bottom {
      position: relative;
      margin: 25px 0 -45px;
    }
  }
  
  &__content-pagination {
    display: flex;
    justify-content: flex-end;
  }
  
  &__content-row {
    margin: 0 -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    
    &--spec {
      margin: 0 -16px 23px;
      justify-content: space-between;
      
      @include media('>desktop') {
        flex-flow: row nowrap;
      }
    }
  }
  
  &__content-col {
    padding: 0 16px;
    width: 100%;
    flex: 0 1 100%;
    
    & + & {
      margin-top: 15px;
      
      @include media('>desktop') {
        margin-top: 0;
      }
      
    }
    
    &--card {
      margin-bottom: 20px;
      
      @include media('>desktop') {
        margin-bottom: 30px;
        
      }
    }
    
    &--50perc {
      
      @include media('>desktop') {
        width: 50%;
        flex: 0 1 50%;
      }
      
    }
  }
  
  &__content-slider {
    margin-bottom: 30px;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
  }
  
  &__content-timeline-keywords {
    margin-bottom: 30px;
  }
  
  &__content-player {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-start;
    margin-bottom: 30px;
  }
  
  &__audio-player {
    flex: 0 1 560px;
    margin-bottom: 30px;
    
    @media (min-width: 890px) {
      margin-bottom: 0;
    }
  }
}

.list-spec {
  margin: 0;
  padding: 0;
  list-style-type: none;
  
  &__item {
    line-height: normal;
    font-size: 14px;
    
    & + & {
      margin-top: 2px;
    }
  }
  
  &__prop {
    color: #808080;
  }
  
  &__value {
    color: $dark-gray;
  }
}

.sidebar {
  width: 100%;
  max-width: 265px;
  display: block;
  &.is-fixed {
    padding-top: 15px;
  }
  
  &__content {
    position: relative;
  }
  
  &__filter {
    margin-bottom: 20px;
  }
  
  &__load-audio {
    position: absolute;
    right: 0;
  }
  
  @at-root .btn--download-audio {
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 165px;
    width: 100%;
    border: 1px solid $gold;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    color: $gold;
    border-radius: 0;
    
    &:active,
    &:hover {
      background-color: $color-hover-header;
      border: 1px solid $color-hover-header;
      color: #fff;
      
      .icon-melody {
        fill: #fff;
      }
    }
    
    &:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .icon-melody {
      margin-right: 8px;
      fill: $gold;
      display: block;
      width: 18px;
      height: 20px;
    }
  }
}

.audio-player__play-btn--disable {
  display: none;
}

.btn {
  &--inactive {
    pointer-events: none;
  }
  &--transparent {
    background: transparent;
    box-shadow: none;
    display: block;
    
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  
  
  &--pause-interview {
    border-radius: 50%;
    display: none;
  }
  
  
  &--play-interview {
    &:active:focus,
    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }
    
    .icon-play {
      .circle {
        stroke: $color-header-normal;
      }
      .triangle {
        fill: $color-header-normal;
      }
      
      circle {
        stroke: $color-header-normal;
      }
      path {
        fill: $color-header-normal;
      }
    }
    
    &:hover {
      .icon-play {
        .circle {
          stroke: $color-hover-header;
        }
        .triangle {
          fill: $color-hover-header;
        }
        
        circle {
          stroke: $color-hover-header;
        }
        path {
          fill: $color-hover-header;
        }
      }
    }
    
    &:active {
      .icon-play {
        .circle {
          stroke: $color-active-header;
        }
        .triangle {
          fill: $color-active-header;
        }
      }
    }
  }
}

.overlay {
  display: none;
  
  .active-overlay & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .1);
    z-index: 500;
  }
  
}

.container {
  width: 100%;
  
  @include media('>desktop') {
    max-width: 970px;
  }
  
  @include media('>desktop') {
    max-width: 1170px;
  }
}

a {
  &:hover {
    color: $color-hover-header;
    text-decoration: none;
    
    svg {
      fill: $color-hover-header;
    }
  }
  
  &:active {
    color: $color-active-header;
    text-decoration: none;
    
    svg {
      fill: $color-active-header;
    }
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.h1 {
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-weight: 500;
  line-height: normal;
  font-size: 40px;
}

.h3 {
  &--second {
    margin-top: 0;
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    
    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-bottom: 10px;
      
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #B3882A;
      }
    }
  }
}

.mfp-bg {
  background-color: rgba(130, 130, 130, .3);
}

.popup-close {
  cursor: pointer !important;
  width: auto !important;
  margin: 0 !important;
  margin-bottom: 7px !important;
}

.icon-close-popup {
  width: 29px;
  height: 29px;
  display: block;
}

button.mfp-arrow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #ccc;
  opacity: 1;
  margin-top: auto;
  transform: translateY(-50%);
  
  &:hover {
    margin-top: auto;
    border: 3px solid #fff;
    
    .icon-arrow-left,
    .icon-arrow-right {
      stroke: #fff;
    }
  }
  
  &:active {
    margin-top: auto;
    transform: translateY(-48%);
    border: 3px solid #fff;
    
    .icon-arrow-left,
    .icon-arrow-right {
      stroke: #fff;
    }
  }
  
  &::before,
  &::after {
    display: none;
  }
  
  .icon-arrow-left,
  .icon-arrow-right {
    display: block;
    width: 10px;
    height: 18px;
    stroke: #ccc;
    z-index: -1;
  }
  
  .icon-arrow-left {
    
  }
  
  &-left {
    left: -60px;
    .icon-arrow-right {
      display: none;
    }
  }
  
  &-right {
    right: -60px;
    .icon-arrow-left {
      display: none;
    }
  }
}

.mfp-bottom-bar {
  background-color: #fff;
}

.mfp-title {
  padding: 12px 20px 11px;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  color: #000000;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.photo-caption {
  margin-right: 20px;
}

.photo-date {
}

.mfp-bottom-bar {
  margin-top: -40px;
}

.overflow-hidden {
  overflow: hidden!important;
}

.text-underline {
  color: #616161;
  text-decoration: underline !important;
  &:hover {
    color: lighten(#616161, 15%);
  }
}
