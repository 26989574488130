.checkbox {
  display: block;
  position: relative;
  margin: 0;
  padding-left: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 14px;
    height: 14px;
    background: #FFF;
    border: 1px solid #616161;
    box-sizing: border-box;
    border-radius: 3px;
    pointer-events: none;

    &::after {
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 16px;
      height: 10px;
      display: none;
      z-index: 10;
      opacity: 1;
      background: url('../images/svg/checker.svg') no-repeat center;
    }
  }

  input:checked + .checkmark {
    background-color: $gold;
    border: 1px solid #997D2F;
  }

  input:checked + .checkmark:after {
    display: block;
    z-index: 10;
    opacity: 1;
  }
}
