@import '../../_styles/settings/mixins';

.gallery-container {
  &--collection {
    a:not(:first-child) {
      display: none;
    }
  }
}

.cemetery-tabs {
  margin-top: 40px;
}

.cemetery-tabs__header {
  position: relative;
}

.cemetery-tabs__title {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
}

.cemetery-info {
  @extend .row;
}

.cemetery-info__image {
  @extend .col-xs-4;
}

.cemetery-info__text {
  @extend .col-xs-8;
  font-size: 16px;
  line-height: 30px;
}

.cemetery-table {
  margin-bottom: 100px;
}

.cemetery-table__row {
  position: relative;
  border-bottom: 1px solid $very-light-gray;
}

.cemetery-table__icon {
  fill: currentColor;

  &--add-image {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px;
  }

  &--info {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    fill: transparent;
    cursor: pointer;
  }
}

.cemetery-table__tooltip {
  position: relative;
}

.cemetery-table__info-btn {
  &:hover {
    color: transparent;
    svg {
      fill: transparent;
    }
  }
  &--active {
    svg {
      position: relative;
      z-index: 700;
    }
  }
}

.cemetery-table__header-cell {
  background-color: #f2f2f2;
  color: $gold;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding-left: 20px;
  word-wrap: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;

  &:first-child,
  &:nth-child(2) {
    padding: 0;
    width: 68px;
    height: 52px;
  }

  &:last-child {
    padding: 0;
    width: 30px;
    border-right: none;
  }
}

.cemetery-table__header-icon {
  display: block;
  fill: $gold;
  margin: 0 auto;

  &--camera {
    width: 26px;
    height: 21px;
  }
  &--info {
    width: 24px;
    height: 24px;
    fill: transparent;
  }
  &--book {
    width: 18px;
    height: 24px;
    fill: transparent;
  }
}

.cemetery-table__header-sort {
  cursor: pointer;

  svg {
    width: 10px;
    height: 6px;
    fill: $gold;
  }

  &::after {
    content: "";
    display: inline-block;
    padding: 3px;
    margin: 0 15px 2px 0;
    transform: rotate(45deg);
    border: solid $gold;
    border-width: 0 1px 1px 0;
    margin-left: 6px;
  }

  &--increase {
    &::after {
      transform: rotate(-135deg);
      margin: 0px 15px 0px 7px;
    }
  }
}

.cemetery-table__body-row {
  border-bottom: 1px solid $very-light-gray;
}

.cemetery-table__body-cell {
  font-size: 14px;
  line-height: 18px;
  height: 52px;
  padding: 0 20px;
  color: $gray-brown;

  &:first-child,
  &:nth-child(2) {
    background-color: #f2f2f2;
    text-align: center;
    border-right: 2px solid #fff;
  }

  &--tooltip {
    position: relative;
  }
}

.cemetery-table__body-icon {
  vertical-align: middle;

  &--add-image {
    width: 26px;
    height: 21px;
  }
  &--info {
    width: 24px;
    height: 24px;
    fill: transparent;
  }
  &--book {
    width: 18px;
    height: 24px;
    cursor: pointer;
  }
}

.content-tabs {
  &__content {
    padding-top: 40px;
  }

  &__desc {
    margin-bottom: 30px;
  }

  //&__content-item {
  //  margin-bottom: 105px;
  //}

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 40px;
  }

  &__image {
    width: 460px;
    margin-right: 30px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 30px;
  }

  &__image--big {
    width: 100%;
  }

  &__footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.page__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cemetery-table__info-popup {
  position: absolute;
  top: 0;
  left: -9999px;
  display: block;
  background-color: #fff;
  width: 1000px;
  z-index: 700;
  opacity: 0;
  text-align: left;

  &--visible {
    left: 100%;
    opacity: 1;
  }
}

.cemetery-table__info-popup-content {
  position: relative;
  padding: 20px;
  border: 1px solid $very-light-gray;
}

.h1--info {
  margin-bottom: 0;
}

.table-tabs {
  display: block;
  flex-shrink: 1;
  flex-grow: 1;

  &__tr {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #D8D8D8;
    &:first-child {
      padding-top: 0;
    }
  }

  &__th {
    width: 180px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 15px;
  }

  &__link {
    color: $dark-gray
  }

  &__td {
    font-size: 16px;
    font-weight: 100;
    line-height: 23px;
    color: $dark-gray;
    .table-tabs__link {
      text-decoration: underline;
    }
    &:hover {
      .table-tabs__link {
        color: $dark-gray;
      }
    }
  }

}

.btn--catalog {
  white-space: nowrap;
  padding: 8px;
  border: 1px solid $gold;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 2px;
  &:hover,
  &:active {
    color: #fff;
    background-color: $gold-light;
  }
  .icon {
    &:not(last-child) {
      margin-right: .45em;
    }
  }
  svg {
    fill: currentColor !important;
  }
}

.cemetery-table__info-popup-icon {
  position: absolute;
  top: 26px;
  transform: translateY(-50%);
  left: -6px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 7px solid transparent;
}

.pointer {
  &__list {
    margin: 0;
    padding: 0;
    &::before {
      position: absolute;
      top: -8px;
      left: 1px;
      content: '';
      width: 1px;
      height: 10px;
      background-color: #D8D8D8;
      transform: rotate(55deg);
    }
  }
  &__item {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    padding-left: 14px;
    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: $gray-brown;
      border-radius: 50%;
    }
    &:nth-child(1) {
      &::before {
        background-color: #00AEEF;
      }
    }
    &:nth-child(2) {
      &::before {
        background-color: #F75BA2;
      }
    }
    &:nth-child(3) {
      &::before {
        background-color: green;
      }
    }
  }
  &::after {
    position: absolute;
    top: -2px;
    left: 0px;
    content: '';
    width: 1px;
    height: 10px;
    background-color: #D8D8D8;
    transform: rotate(-55deg);
  }
}

.cemetery-table__info-popup-close {
  position: absolute;
  top: -36px;
  right: 0;
  cursor: pointer;
}

.slider-cemetery {
  position: relative;
}

.owl-item {
  &:hover {
    .slider__play-nav {
      &::before {
        opacity: 1;
      }
    }
  }
}

.slider__play-nav {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .3);
    opacity: 0;
    transition: .20s;
    z-index: 2;
  }
}

.cemetery-table__tooltip-info {
  position: absolute;
  top: 0;
  left: 0;
  word-wrap: normal;
  width: 300px;
}

.cemetery__tooltip {
  position: fixed;
  overflow: hidden;
  left: -9999px;
  top: 0;
  max-width: 40vw;
  padding: 5px 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #E2E2E2;
  z-index:99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  word-break: break-word;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
  &._to-right {
    transform: translateX(-100%) translateY(10px);
  }
}
