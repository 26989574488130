// footer styles

.page-footer{
  background-color: #363636;

  &__inner {
    padding-top: 50px;
    padding-bottom: 35px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;


    @include media('>tablet') {
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  &__site-info {
    width: 100%;
    flex: 0 1 100%;
    flex-basis: 100%;
    max-width: 100%;

    @include media('>tablet') {
      width: 100%;
      flex: 0 1 395px;
      flex-basis: 395px;
      max-width: 395px;
    }
  }

  &__col {
    flex: 0 1;
    flex-basis: auto;
    color: #fff;

    &:nth-child(2) {
      flex-basis: 290px;
    }

    &:nth-child(3) {
      flex-basis: 295px;
    }

    &:last-of-type {
      flex-basis: 168px;
    }
  }

  &__inner-cols {
    flex: 0 1 463px;
  }

  &__inner-col {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;

    @include media('>tablet') {
      flex-flow: row nowrap;
    }
  }

  &__col-header {
    margin-bottom: 9px;
    color: $light-brown;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 18px;
  }

  &__list-item {
    & + & {
      margin-top: 7px;
    }
  }

  &__list-link {
    color: #fff;
    line-height: 27px;
    font-size: 13px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    svg {
      display: block;
      fill: $light-brown;
    }

    &:hover {
      svg {
        fill:$color-hover-header;
      }
    }

    &:active {
      svg {
        background-color: $color-active-header;
      }
    }

    .icon-phone {
      width: 14px;
      height: 14px;
      flex: 0 1 14px;
    }

    .icon-email {
      width: 14px;
      height: 10px;
      flex: 0 1 14px;
    }

    .icon-point {
      width: 13px;
      height: 15px;
      flex: 0 1 15px;
      margin-left: 2px;
    }
  }

  &__inner-icon {
    margin: 8px 5px auto 0;
    flex: 0 1 16px;
    width: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  @at-root .footer-social {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
  }

  &__address-text {
    margin-top: 6px;
    margin-left: 2px;
    line-height: 16px;
    font-size: 13px;
  }

  &__copyright {

    &-text {
      margin-bottom: 13px;
      text-align: right;
      width: 100%;
      line-height: 18px;
      font-size: 13px;
      color: #AD9978;
    }
  }

  &__site-made {
    text-align: right;
    line-height: 18px;
    font-size: 13px;
    color: #808080;
    
    a {
      color: #b90a20;

      &:hover {
        color: $color-hover-header;
      }

      &:active {
        color: $color-active-header;
      }
    }
  }
}

.page-footer-info {
  color: #fff;
  line-height: 18px;
  font-size: 13px;

  &__header {
    margin-bottom: 31px;
  }

  &__logo {
    display: block;
    max-width: 247px;
    width: 100%;
    height: auto;
  }

  &__body {
    margin-bottom: 33px;
    max-width: 265px;

    p {
      line-height: 18px;
      font-size: 13px;
    }
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__logo-second {
    display: block;
    flex: 0 1 auto;
    max-width: 63px;
    width: 100%;
    height: auto;
    margin-right: 10px;
  }

  &__logo-second-descr {
    font-size: inherit;
    line-height: inherit;
  }

}

.footer-social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &__title {
    margin-right: 7px;
    line-height: 18px;
    font-size: 13px;
    color: $light-brown;
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 0;
    margin: 0;
    padding: 0;
  }

  &__item {
    flex: 0 1 auto;

    & + & {
      margin-left: 10px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $light-brown;

    &:hover {

      background-color: $color-hover-header;

      svg {
        fill:#363636;
      }
    }

    &:active {
      background-color: $color-active-header;
    }

    svg {
      display: block;
      fill:#363636;
    }

    .icon-facebook {
      flex: 0 1 8px;
      height: 16px;
    }

    .icon-vk {
      flex: 0 1 18px;
      height: 11px;
    }

    .icon-twitter {
      flex: 0 1 18px;
      height: 13px;
    }

    .icon-google-plus {
      flex: 0 1 20px;
      height: 13px;
    }
  }
}