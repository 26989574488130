.content-tabs__menu {
  flex: 1 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  border-bottom: 2px solid $gold;
  &--right {
    justify-content: flex-start;
  }
}
.content-tabs__link-icon {
  top: 50%;
  fill: $gold;
  width: 16px;
  height: 16px;
  line-height: 1;
  &:not(last-child) {
    margin-right: 3px;
  }

  &--list {
    left: 6px;
    width: 11px;
    height: 15px;
  }
  &--map {
    left: 4px;
    width: 16px;
    height: 16px;
  }
  &--tombstone{
    left: 4px;
    width: 14px;
    height: 15px;
  }
  &--play {
    width: 14px;
    height: 19px;
    left: 50%;
    fill: #fff;
  }
}
.content-tabs__link {
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  padding: 2px 5px;
}
.content-tabs__item {
  overflow: hidden;
  position: relative;
  border-left: 1px solid;
  padding-right: 10px;
  color: $gold;
  user-select: none;
  &[disabled] {
    opacity: .5;
    pointer-events: none;
    color: $gold !important;
  }
  &::before {
    z-index: 1;
    border: 1px solid;
    border-radius: 0 2px 0 0;
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -15px;
    right: 10px;
    background: #fff;
    content: '';
    transform: skew(22.5deg);
    transform-origin: top right;
  }
  & + & {
    margin-right: -5px;
  }
  &--active {
    z-index: 2;
    &::before {
      background: $gold;
    }
    .content-tabs__link {
      color: #fff;
      z-index: 10;
      pointer-events: none;
      &:hover {
        color: #fff;

        .content-tabs__link-icon {
          fill: #fff;
        }
      }
      .content-tabs__link-icon {
        fill: #fff;
      }
    }
  }
}

.content-tabs__content {
  padding-top: 30px;
  &--epigraphic-card {
    padding-top: 0;
  }
}

.content-tabs__content-item {
  display: none;

  &--active {
    display: block;
  }
}
