.filter-search {

  &__filters {
    position: relative;
    z-index: 10;
  }

  &__tabs {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: -1px;
  }

  &__submit {
    display: flex;
    align-items: center;
    padding: 0;
    margin: -5px 0 5px;
    border: none;
    background: transparent;
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
    .icon-apply-filter {
      width: 20px;
      height: 20px;
      margin-top: 4px;
      fill: #616161;
    }
  }

  @at-root .nav-tabss {
    position: relative;
    flex: 0 1 70px;
    min-width: 70px;
    border: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 0;
    margin: 0 10px 0 0;
    font-size: 0;
    overflow: hidden;
    margin-bottom: -1px;
    
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color-normal-gray;;
      z-index: 10;
    }

    &__item {
      flex:  0 1 auto;
      position: relative;

      & + & {
        margin-left: 3px;
      }

      &--active {
        z-index: 10;
      }
    }

    &__link {
      position: relative;
      display: block;
      padding: 5px 5px 4px 8px;
      border: 1px solid $color-normal-gray;
      border-bottom: 0;
      background-color: #F2F2F2;
      border-radius: 0 3px 0 0;

      .nav-tabs__item--active & {
        background-color: #fff;
      }

      &::before {
        top: -1px;
        right: -5px;
        position: absolute;
        content: '';
        display: block;
        width: 6px;
        height: 26px;
        background-color: $color-normal-gray;;
        transform: rotate(-18deg);
        border-radius: 0 3px 0 0;
      }

      &::after {
        top: 0;
        right: -4px;
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 26px;
        background-color: #F2F2F2;
        transform: rotate(-18deg);
        border-radius: 0 3px 0 0;

        .nav-tabs__item--active & {
          background-color: #fff;
        }
      }

      &:hover {
        .icon-filters {
          stroke: $color-hover-gray;
          fill: $color-hover-gray;
        }

        .icon-search {
          fill: $color-hover-gray;
        }
      }

      &:hover {
        .icon-filters {
          stroke: $color-active-gray;
          fill: $color-active-gray;
        }

        .icon-search {
          fill: $color-active-gray;
        }
      }

      svg {
        fill: $dark-gray;
        position: relative;
        z-index: 10;

        .nav-tabs__item--active &{
          stroke: $color-hover-gray;
          fill: $color-hover-gray;
        }
      }

      .icon-filters {
        display: block;
        width: 15px;
        height: 15px;
        stroke: $color-normal-gray;
        fill: $color-normal-gray;
      }

      .icon-search {
        display: block;
        width: 14px;
        height: 14px;
        fill: $very-light-gray;
      }

      &--cemetery {
        background-color: #fff;
        
        &::after {
          background-color: #fff;
        }
      }
    }
  }

  &__clear-link {
    border: 0 !important;
    appearance: none;
    box-shadow: none !important;
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    line-height: 15px;
    font-size: 11px;
    margin-bottom: 4px;
    padding: 0;
    @extend a;
  }

  &__input {
    width: 100%;
    background-color: #fff;
    position: relative;
    z-index: 10;

    form {
      display: block;
      width: 100%;
    }

    input {
      width: 100%;
      display: block;
      height: 40px;
      padding: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      font-size: 16px;
      color: #ADADAD;
      background-color: #fff;
      border: 1px solid $very-light-gray;

      &:focus {
        color: $dark-gray;
      }
    }
  }

  &__info-result {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__summ-items {
    font-size: 14px;
    color: $gray-brown;
  }

  // &__pagination {
  //   margin-bottom: 20px;
  // }

  &__tab {
    display: none;
    margin-top: 1px;
    margin-bottom: 19px;

    &--active {
      display: block;
    }
  }
}
