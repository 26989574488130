.filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &--4-per-row,
  &--5-per-row {
    margin-top: 2px;
  }
  &--sidebar {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .filters__item {
      margin-top: -1px;
      min-width: 0;
    }
    form {
      width: 100%;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &__result-list {
    &--tabs {
      & > .filters__result-item {
        &:not([data-tab="1"]) {
          display: none;
        }
        &--search-result {
          display: inline-block !important;
        }
      }
    }
  }
  &__item {
    min-width: 220px;
    position: relative;
    &--not-logged {
      .filters__result-list {
        height: auto;
      }
      .filters__title-select  {
        color: $silver-chalice;
      }
      .icon-indicator {
        fill: $silver-chalice;
      }
      .filters__select--active {
        .filters__title-select {
          color: $silver-chalice;
        }
        .filters__icon-indicator {
          .icon-indicator {
            fill: $silver-chalice;
          }
        }
      }
    }
    .filters--4-per-row & {
      flex: 1 1 percentage(1/4);
      min-width: 0;
      margin-top: -1px;
    }
    .filters--5-per-row & {
      flex: 1 1 percentage(1/5);
      min-width: 0;
      margin-top: -1px;
    }
    &--static {
      .filters__dropdown {
        display: none;
        opacity: 1;
        visibility: visible;
        position: static;
        transform: none;
      }
      .filters__select--active + .filters__dropdown {
        display: block;
      }
    }
  }
  .multiselect,
  .btn-group {
    width: 100% !important;
  }
  .btn.multiselect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border-radius: 0;
    border-color: $very-light-gray;
    background-color: #fff;
    padding: 9px 23px 9px 20px;
    line-height: 20px;
    font-size: 16px;
    color: #555555;
    height: 42px;

    &:active,
    &:hover,
    &:focus {
      background-color: #fff;
      border-color: $very-light-gray;
      box-shadow: none;
      outline: none;
    }
  }

  .dropdown-menu {
    box-shadow: none;
    border: 1px solid $very-light-gray;
    margin-top: -1px;
    padding-top: 0;

    &::after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 22px;
      left: auto;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #fff transparent;
    }

    &::before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100.2%;
      right: 22px;
      left: auto;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $very-light-gray transparent;
    }
  }

  .caret {
    margin: 0;
    display: block;
    flex: 0 1 14px;
    width: 14px;
    height: 8px;
    //transition: transform 150ms ease-out;
    background: url(../images/svg/angle-down-dark-gray.svg) no-repeat center;
    border: 0;

    @at-root .open .caret {
      transform: rotate(-90deg);
    }
  }

  .multiselect-container .input-group {
    margin: 0;
    border: 0;
  }

  .input-group-addon {
    border: 0;
    background-color: #fff;
  }

  .form-control {
    border: 0;
  }

  .filter {
    border-bottom: 1px solid $very-light-gray;
    .btn-default {
      border: 0;

      &:active,
      &:focus,
      &:hover,
      &:active {
        background-color: #fff;
        outline: none;
        box-shadow: none;
      }
    }

    .input-group-addon {
      padding-left: 20px;
      color: #ADADAD;
    }
  }

  .multiselect-search {
    display: block;
    height: 40px;
    padding-left: 0;
    line-height: 20px;
    font-size: 14px;
    color: #ADADAD;
  }

  .glyphicon-search::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background: url('../images/svg/search-gray.svg') no-repeat center;
  }

  .glyphicon-remove-circle::before {
    content: '';
    display: none;
  }

  .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: #fff;
  }

  &__select {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px;
    border: 1px solid #D8D8D8;
    background-color: #fff;
    user-select: none;
    cursor: pointer;
  }

  &__title-select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
    font-size: 16px;
    color: #555555;

    .filters__select--active & {
      color: $gold;
    }
  }

  &__icon-indicator {
    margin-left: 8px;

    .icon-indicator {
      width: 14px;
      height: 8px;
      display: block;
      //transition: all 250ms;

      .filters__select--active & {
        fill: $gold;
        transform: rotate(-180deg);
      }
    }
  }

  &__dropdown {
    z-index: 999;
    margin-top: -1px;
    width: 100%;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    //height: 380px;
    background-color: #fff;
    border: 1px solid $very-light-gray;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    will-change: transform;
    transform: translateY(4px);
    transition: .15s;
    .filters__select--active + & {
      opacity: 1;
      visibility: visible;
      user-select: initial;
      transform: translateY(0);
    }
    &::before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100.2%;
      right: 19px;
      left: auto;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #D8D8D8 transparent;
    }
    &::after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 19px;
      left: auto;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #fff transparent;
    }
  }

  &__search {
    flex: 0 0 39px;
    position: relative;
    padding: 9px 20px 9px 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $very-light-gray;
    background: #fff;
    .icon-search {
      top: 15px;
      left: 20px;
      position: absolute;
      width: 14px;
      height: 14px;
      fill: #555;
    }
  }

  &__search-input {
    display: block;
    width: 100%;
    border: 0;
    color: #000;
    line-height: 20px;
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }

  &__result {
    height: 100%;

    &-inner {
      overflow: hidden;
      flex: 0 0 340px;
      position: relative;
      height: 340px;
    }

    &-list {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      padding: 0;
      margin: 0;
      list-style-type: none;

      .filters__result--inputsearch & {
        height: auto;
        max-height: 250px;
      }
    }

    &-item {
      display: inline-block;
      width: 100%;
      padding: 13px 20px;
      list-style-type: none;

      .icon-arrow-down {
        display: block;
        width: 10px;
        height: 6px;
        margin-left: auto;
        transform: rotate(-90deg);
        //transition: all 250ms;
        fill: $gold;
      }

      &--sub-list {
        padding-bottom: 13px;
        background-color: #fff;
        font-weight: 400;
      }

      &--main-category {
        background-color: #F2F2F2;
        border-bottom: 1px solid $very-light-gray;
        font-weight: bold;

        .checkbox {
          font-weight: 500;
        }

        .icon-arrow-down {
          display: block;
          transform: rotate(90deg);
        }
      }
    }

    &--two-columns {
      .filters__result-list {
        padding: 15px 20px;
        height: 220px;
      }
      .filters__result-item {
        padding: 0;
        margin: 10px 0;
        width: 49%;
        height: 20px;
      }
      .filters__checkbox-result {
        padding: 0;
        .checkmark {
          display: none;
        }
      }
    }

    &--calendar {
      padding: 8px 5px;
      user-select: none;

      &.PACalendar {
        min-height: 320px;
        > header,
        > section {
          margin-left: 0;
        }
        .PAheader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
          margin: 0;
          .PAHmonth {
            font-weight: bold;
            font-size: 14px;
            color: $gold;
            text-transform: capitalize;
          }

          .PAHprev, .PAHnext {
            border: solid $gold;
            border-width: 0 2px 2px 0;
            border-radius: 1px;
            height: 10px;
            width: 10px;
            display: inline-block;
            color: transparent;
            user-select: none;
            transform: rotate(135deg);

            &:hover {
              background: transparent;
              border-radius: 1px;
            }
          }
          .PAHprev {
            transform: rotate(135deg);
          }

          .PAHnext {
            transform: rotate(-45deg);
          }
        }
        .PAweek {
          margin-top: 10px;

          span {
            font-weight: normal;
            font-size: 12px;
            color: #ADADAD;
            text-transform: capitalize;
          }
        }
        .PAmonth {

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ADADAD;

            &.PAactive {
              border-radius: 50%;
              color: #616161;
              &:hover {
                background: $gold;
              }
            }

            &.PAbetween, &.PAto, &.PAfrom  {
              background: $gold;
              border-radius: 50% !important;
              color: #ffffff;
            }

            &.PAbetween {
              opacity: .7;
            }
          }
        }
      }
    }
  }

  &__checkbox-result {
    font-weight: 400;
    &--active {
      color: $gold;
      font-weight: bold;
    }
  }

  &__inner-item {
    flex: 1 1 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &--sub-list {
      padding-bottom: 0;
    }
    .filters__checkbox-result {
      flex: 1 1 100%;
    }
  }

  &__result-sublist {
    @extend .filters__result-list;
    display: none;
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex: 1 1 100%;
    transition: all 0.5s ease 0s;
    background-color: #fff;
  }

  &__alphabet {
    padding: 12px 20px;
    border-bottom: 1px solid #D8D8D8;
    height: 110px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &-row {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      &:first-child {
        margin-top: 0;
      }
    }

    &-letter {
      margin: 0 5px;
      text-transform: uppercase;
      cursor: pointer;

      &--active {
        font-weight: bold;
        color: $gold;
      }
    }
  }

  &__tabs {
    display: flex;
    padding: 4px 2px 0;
    align-items: center;
    justify-content: center;
  }

  &__tab {
    flex: 1;
    border: 1px solid #d8d8d8;
    height: 35px;
    margin: 0 2px;
    padding: 0 15px;
    background: #fff;
    text-align: left;
    outline: none;
    &--active {
      font-weight: bold;
      color: $gold;
    }
  }

  &__date-range {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-bottom: 1px solid #D8D8D8;

    &-input {
      height: 40px;
      width: 100%;
      padding: 7px;
      outline: 0;
    }

    &-separator {
      margin: 0 6px;
    }
  }
}

.btn--open-submenu-filters {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
}

.filters__result-item--main-category >  .filters__inner-item .btn--open-submenu-filters,
.filters__result-item--has-sublist > .filters__inner-item > .btn--open-submenu-filters{
  display: flex;
}

.btn--close-input-search-keywords {
  display: none;
  position: absolute;
  padding: 10px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  @at-root .btn--active {
    display: block;
  }

  &:active,
  &:hover {
    .icon-close-input {
      fill: $gold;
    }
  }

  .icon-close-input {
    display: block;
    width: 20px;
    height: 20px;
    fill: #ADADAD;
  }
}
