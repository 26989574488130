// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$pic-ratio: 9/16 !default;

$dark-gray: #333;
$gray-brown: #616161;
$green-gray: #F8F8F8;
$light-gray: #E3E6E8;

$very-light-gray: #D8D8D8;
$gold-light: #CF9D30;
$gold: #B3882A;
$light-brown: #AD9978;
$black: #1e1e1e;
$red: #ff0000;
$white: #fff;
$silver-chalice: #ADADAD;

$border-color: $light-gray;

$color-header-normal: $gray-brown;
$color-hover-header: $gold-light;
$color-active-header: $gold;

$color-normal-gray: $very-light-gray;
$color-hover-gray: $gray-brown;
$color-active-gray: $dark-gray;

$base-font-sans-serif: 'PT Sans', sans-serif;
$base-font: $base-font-sans-serif;


// tables
$table-hover-bg: #f2f2f2;
$table-border-top-color: #fff;
$table-border-bottom-color: #d8d8d8;

// Body
$base-text-color: #090909 !default;
$base-background-color: $green-gray !default;
$font-size-base: 10px !default;
$line-height-base: 1.2rem !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

// Indents
$gap: 1.5rem;
$trim: $gap;
$trim-middle: 2rem;
$trim-big: 3rem;

$base-min-width: 32rem;
$max-content-width: 144rem;
$max-container-width: $max-content-width + $trim*2;
$max-width: 1170px;

$width-inner-content: 1140;
$width-sidebar: 265;
$width-content: $width-inner-content - $width-sidebar - 30;



// Links
//$base-link-color: $brown;
$base-link-hover-color: $dark-gray ;

// Buttons
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: .4em .7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: .9rem 1rem !default;
$form-element-background: $white !default;
//$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
//$placeholder-color: $gray !default;

// Headers
$h1-font-size: 3.2rem !default;
$h2-font-size: 3.6rem !default;
$h3-font-size: 2.4rem !default;
$h4-font-size: 2rem !default;
$h5-font-size: 1.7rem !default;
$h6-font-size: 1.5rem !default;

//$headings-font-family: $roboto-font !default;
$headings-color: $black !default;

$animation-speed: .3s;

$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px
);

$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);
