// keywords styles
.keywords {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  &--tags {
    min-height: 32px;
  }

  &__item {
    position: relative;
    padding: 2px 23px 0 5px;
    margin: 0 5px 10px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    background-color: $gold;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    user-select: none;
    &:hover {
      background-color: $color-hover-header;
    }
    svg {
      &:hover {
        fill: #616161;
      }
    }
    &--active {
      background-color: #616161;
      &:hover {
        background-color: $dark-gray;
        svg {
          fill: #fff;
        }
      }
      svg {
        &:hover {
          fill: $color-hover-header;
        }
      }
    }
    &--input {
      background: #616161;
      &:hover {
        background: lighten(#616161, 10%) !important;
      }
      svg {
        &:hover {
          fill: darken(#fff, 30%) !important;
        }
      }
    }
  }
  &__category {
    color: #d8d8d8;
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
    margin-right: .5rem;
  }

  &__link {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      color: $color-hover-gray;
    }
    
    &:active {
      color: $color-active-gray;
    }

    & + & {
      margin-left: .5rem;
    }
  }

  @at-root .btn--del-keywords {
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 0;
    padding: 1px;
    margin-left: 1px;

    svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: #fff;
    }

    &:active {

      svg {
        fill: $dark-gray;
      }
    }
  }
}
