// navbar styles

.container--navbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 80px;

  &::before,
  &::after {
    display: none;

    @include media('>tablet') {
      display: none;
    }
  }

  @include media('>tablet') {
    justify-content: space-between;
  }
}

.navbar {
  min-height: 0;
  border-bottom: 1px solid $border-color;

  &__menu {
    display: block;
    position: absolute;
    top: -15px;
    width: 320px;
    left: 0;
    background-color: $green-gray;
    z-index: 600;
    transition: transform .28s, opacity linear .2s;
    transform: translateY(-110%);
    opacity: 0;

    @include media('>tablet') {
      width: 360px;
    }
    &._active {
      display: block;
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__toggle-lang {
    padding: 30px;
    border-bottom: 1px solid $border-color;

    @at-root .link--toggle-lang {
      color: $gold;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 14px;
      display: none;

      &:hover {
        color: $color-hover-header;
        text-decoration: none;
      }

      &:active {
        color: $color-active-header;
        text-decoration: none;
      }

      &._active {
        display: inline-block;
      }
    }
  }

  @at-root .btn--close-main-menu {
    position: absolute;
    top: 17px;
    right: 0;
    font-size: 0;
    padding: 10px 13px;

    &:active,
    &:focus {
      outline: 0;
    }

    &:hover svg {
      fill: $color-hover-header;
    }

    &:active svg {
      fill: $color-active-header;
    }

    svg {
      fill: $dark-gray;
      width: 25px;
      height: 25px;
    }
  }

  &-header {
    position: relative;
    float: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &-toggle {
    float: left;
    margin-right: 23px;

    @include media('>tablet') {
      display: block;
    }
  }

  &-collapse {
    @include media('>tablet') {
      display: none;
    }
  }

  &-default {

    .navbar-toggle {
      border: 0;
      margin: 0 29px 0 0;

      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
      }

      .icon-bar {
        background-color: $dark-gray;
      }
    }
  }

  &__main-nav {
    float: none;
    display: none;

    @include media('>tablet') {
      flex: 1;
      display: block;
    }
  }

  &-brand {
    margin: 0;
    width: 245px;
    height: 50px;
    background: url(../images/logo_color.png) left center no-repeat,
    url(../images/logo_color-1.png) right center no-repeat;

    @include media('>tablet') {

    }
  }

  &__partner {
    flex: 0 1 auto;
    float: none;
    margin: 0 0 0 15px;
  }

  &-toggle {
    .icon-bar {
      width: 20px;

      & + .icon-bar {
        margin-top: 6px;
      }
    }
  }

  &__search {
    flex: 0 1 auto;
    form {
      display: block;
    }
  }

  &__user {
    float: none;
    flex: 1;
    max-width: 270px;
    padding: 0 15px;

    @at-root .user-nav {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &__item {
        padding: 0 10px;
        a {
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-size: 16px;
          color: $dark-gray;
          white-space: nowrap;
          &:hover {
            color: $color-hover-header;
            text-decoration: none;
          }
          &:active {
            color: $color-active-header;
            text-decoration: none;
          }
          span {
            vertical-align: middle;
          }
        }

        &--login {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          svg {
            margin-right: 10px;
            line-height: 30px;
            vertical-align: middle;
            display: inline-block;
            width: 25px;
            height: 25px;
            fill: $dark-gray;
          }
        }

        &--lang {
          margin-left: 10px;
          a {
            color: $gold;
            text-decoration: none;
          }
        }
      }
    }
  }

  .collapse.in {
    display: block;
  }
}

.btn--search-navbar {
  display: block;
  font-size: 0;
  background-color: transparent;

  svg {
    width: 20px;
    height: 20px;
    fill: $dark-gray;
  }

  &:hover svg {
    fill: $color-hover-header;
    text-decoration: none;
  }

  &:active svg {
    fill: $color-active-header;
    text-decoration: none;
  }
}

.btn--open-main-menu {
  &:hover .icon-bar {
    background-color: $color-hover-header;
    text-decoration: none;
  }

  &:active .icon-bar {
    background-color: $color-active-header;
    text-decoration: none;
  }
}

.main-nav {
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    padding: 0 15px;
    list-style-type: none;
  }

  &__item {
    padding: 0 10px;
  }

  &__link {
    white-space: nowrap;
    display: block;
    color: $dark-gray;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 16px;

    &:hover {
      color: $color-hover-header;
      text-decoration: none;
    }

    &:active {
      color: $color-active-header;
      text-decoration: none;
    }
  }
}

.main-menu {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;

  &__item {
    width: 100%;
    max-height: 80px;
    min-height: 80px;
    height: 100%;
    overflow: hidden;
    transition: max-height .35s;
    border-bottom: 1px solid $border-color;

    &:hover {
      max-height: 1400px;
      transition: max-height .35s;
      background-color: #fff;
    }

    &--sub-menu {
      height: auto;
      min-height: 0;
      max-height: none;
      overflow: visible;
      border-bottom: 0;
    }
  }

  &__link {
    padding: 27px 30px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 18px;
    color: $dark-gray;
    transition: background-color 350ms ease-out;

    .main-menu__item:hover & {
      background-color: #fff;
      //padding-bottom: 10px;
    }

    &:hover {
      color: $color-hover-header;
      text-decoration: none;
    }

    &:active {
      color: $color-hover-header;
      text-decoration: none;
    }

    &--sub-menu {
      padding: 10px 30px;
      display: block;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 14px;
    }
  }

  &__icon-arrow-sub-menu {
    svg {
      width: 14px;
      height: 8px;
      transition: transform ease-out 350ms;

      .main-menu__item:hover & {
        fill: $color-hover-header;
        text-decoration: none;
        transform: rotate(-180deg);
      }

      .main-menu__link:active & {
        fill: $color-active-header;
        text-decoration: none;
      }
    }
  }

  &__sub-menu {
    display: block;
    list-style-type: none;
    opacity: 0;
    padding: 0 0 10px;
    margin-top: -20px;
    transition: opacity ease-out 350ms;

    .main-menu__item:hover & {
      opacity: 1;
    }
  }

}

