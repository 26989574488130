.icon {
  display: inline-block;
  vertical-align: middle;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
  &--size {
    &_sm {
      width: 16px;
      height: 16px;
    }
    &_md {
      width: 20px;
      height: 20px;
    }
    &_lg {
      width: 28px;
      height: 28px;
    }
  }
  &--muted {
    svg {
      color: #c4c4c4;
    }
  }
}
